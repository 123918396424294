













import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { UserProjectsMutations } from '@store/modules/user-projects/Types';
import { namespace } from 'vuex-class';
import { IAllUsersUser, IUserApp } from '@store/modules/user-projects/Interfaces';

const NSUserProjects = namespace('storeUserProjects');

@Component({
    name: 'PopupFiltersProjects',
    components: {
        PopupFilters: () => import('@components/popups/PopupFilters.vue'),
    }
})

export default class PopupFiltersProjects extends Vue {
    $refs!: {
        popupFilter: HTMLFormElement,
    }

    @Prop({}) projectsByUsers!: Map<IAllUsersUser, IUserApp>;
    @Prop({}) filteredUsersCount!: number;

    @NSUserProjects.Getter('filters') filters!: any[];
    @NSUserProjects.Getter('filtersApply') filtersApply!: boolean;
    @NSUserProjects.Mutation(UserProjectsMutations.M_SET_FILTERS) setFilters!: (payload: any[]) => void;
    @NSUserProjects.Mutation(UserProjectsMutations.M_SET_FILTERS_APPLY) setFiltersApply!: (payload: boolean) => void;

    @Watch('projectsByUsers')
    watchProjectsByUsers() {
        if (this.projectsByUsers && this.projectsByUsers.size) {
            this.setOptions();
        }
    }

    @Watch('filteredUsersCount')
    watchCount() {
        if (this.filtersApply) {
            this.$emit('set-filters-preview', this.popupData);
        }
    }

    popupData: any[] = [
        {
            title: 'Пользователь',
            block: 'user',
            type: 'select',
            key: 'key.fullName',
            options: [],
            selected: [],
        },{
            title: 'Проект',
            block: 'project',
            type: 'select',
            key: 'value.title',
            options: [],
            selected: [],
        },{
            title: 'Роль',
            block: 'role',
            type: 'select',
            key: 'value.roles',
            options: [],
            selected: [],
        },
    ];

    applyFilters() {
        this.setFiltersApply(true);
        this.$emit('set-filters-preview', this.popupData);
        this.closePopup();
    }

    cancelFilters() {
        this.setFiltersApply(false);

        this.popupData.forEach((item) => {
            if (item.type === 'select') {
                item.selected = [];
            }
        });

        this.setFilters([]);
        this.$refs.popupFilter.resetFilters();
    }

    setOptions() {
        this.popupData.forEach((item) => {
            if (item.block === 'user') {
                item.options = this.allUsers();
            }
            if (item.block === 'project') {
                item.options = this.allProjects();
            }
            if (item.block === 'role') {
                item.options = this.allRoles();
            }
        })
    }

    allUsers(): any[] {
        const added: string[] = [];
        const output: any[] = [];
        if (this.projectsByUsers) {
            for (const [key, value] of this.projectsByUsers) {
                if (key.fullName && !added.includes(key.fullName)) {
                    added.push(key.fullName);
                    output.push({label: key.fullName});
                }
            }
        }
        return output;
    }

    allProjects(): any[] {
        const added: string[] = [];
        const output: any[] = [];
        if (this.projectsByUsers) {
            for (const [key, value] of this.projectsByUsers) {
                if (value.title && !added.includes(value.title)) {
                    added.push(value.title);
                    output.push({label: value.title});
                }
            }
        }
        return output;
    }

    allRoles(): any[] {
        const added: string[] = [];
        const output: any[] = [];
        if (this.projectsByUsers) {
            for (const [key, value] of this.projectsByUsers) {
                value.roles.forEach((role) => {
                    if (role.title && !added.includes(role.title)) {
                        added.push(role.title);
                        output.push({label: role.title});
                    }
                })
            }
        }
        return output;
    }

    changeSelected(e, byPreview = false) {
        for (const item of this.popupData) {
            if (item.type === 'select' && item.title === e.section) {
                item.selected = e.selected;
            }
        }
        this.updateStoreFilters();
        if (byPreview) {
            this.$refs.popupFilter.changeSelectedData(e);
        }
    }

    updateStoreFilters() {
        const filters: any[] = [];
        this.popupData.forEach((item) => {
            if (item.type === 'select' && item.selected.length) {
                filters.push(item);
            }
        });
        this.setFilters(filters);
    }

    openPopup() {
        this.$refs.popupFilter.openPopup();
    }

    closePopup() {
        this.$refs.popupFilter.closePopup();
    }
}
